import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const Header = (props) => {

    return (

        <header className='sticky-top'>

            <div className='container'>

                <div className='row align-items-center'>

                    <div className='col-md-8'>

                        {/* <h3>GAPCRUD CONSULTING</h3> */}
                        <Link to="/"><img src={require("../assets/imgs/gapcrud.png")} className='img-fluid my-2' style={{borderRadius:5}} width={150} /></Link>
                        

                    </div>

                    <div className='col-md-4 text-center text-md-right'>
                        {/* <small>info@capsulelabs.in</small> */}
                    </div>

                </div>

            </div>

        </header>

    )

};

export default Header;