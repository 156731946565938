import React from "react";


const Baseline = () => {
    return (

        <div>

            <p>Our assessments form a basis for creating a customized growth strategy that is right for your institution. Tangible benefits include:</p>

            <ul>
                <li>2x Student Placement CTC</li>
                <li>3x Workshops & Conferences</li>
                <li>20% Lower Admission Overheads</li>
                <li>30% Higher Faculty Efficiency</li>
                <li>40% Lower Placement Overheads</li>
                <li>5Cr+ Divestments from Startup Exits</li>
            </ul>

        </div>

    )
}

export default Baseline;