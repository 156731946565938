import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const Banner = (props) => {

    

    return (

        <div className='fullht home_banner position-relative d-flex flex-wrap align-items-end'>

            <div className='container'>

                <div className='row'>

                    <div className='col-12 text-white'>

                        <h1 className='mb-0'>TURBOCHARGED TOPLINES</h1>

                        {/* <p className='mb-4'>Helping Higher Education Institutions and Incubators monetize intangible assets.</p> */}

                        <p className='mb-4'>Monetizing assets for Higher Education Institutions & Incubators</p>

                        <Link to="/#brands" className='btn border text-white borderradiusRounded px-4 mt-2'><i className="fa fa-envelope-o" aria-hidden="true"></i> &nbsp; Mail Us</Link>

                    </div>
                </div>
            </div>

        </div>

    )

};

export default Banner;