import React from "react";


const Growth = () => {
    return (

        <div>

            <p>With our straightforward 4-phase engagement process you can capitalize on innovation ecosystems, faculty efficiencies, alumni relations, and student placements. </p>

            <ul>
                <li>Our <b>leadership workshop</b> enables you to discover hidden institutional potential. </li>
                <li>Our <b>baseline assessments</b> maps out intangible assets.</li>
                <li>Our <b>detailed project report</b> contains the monetization strategy and timelines. </li>
                <li>Our <b>delivery support</b> ensures timely implementation in line with the capitalization goals. </li>
            </ul>

        </div>

    )
}

export default Growth;