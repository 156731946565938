import React, { useState, useEffect } from 'react';

import Banner from './components/Banner';
import About from './components/About';
import Fulltext from './components/Fulltext';
import Brands from './components/Brands';

import Growth from './components/Growth';
import Engagement from './components/Engagement';
import Baseline from './components/Baseline';

const Index = (props) => {

    return (

        <React.Fragment>
            <Banner />
            <About/>
            {/* <Fulltext id="vision" side="right" text="Our Vision" text_content={Vision} /> */}
            <Fulltext id="strategy" side="left" text="Our Engagement Process" text_content={Growth} />
            <Fulltext id="engagement_model" side="right" text="Baseline Assessment" text_content={Engagement} />
            <Fulltext id="assessments" side="left" text="3x Growth Strategy" text_content={Baseline} />
            <Brands loc={props.loc}/>
        </React.Fragment>

    )

};

export default Index;