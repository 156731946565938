import React, { useState, useEffect } from "react";
import Slider from "react-slick";
const About = () => {

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay:true,
        fade:true
    };
    return (

        <section className="fullht d-flex flex-wrap align-items-center faded about">
            <div className="container">

                <div className="row">

                    <div className="col-12 text-center">

                        <h2>About Us</h2>
                        <br />
                        <p>We strive to become a leading management consulting firm in India for Higher Education Institutions, Technology Business Incubators and Startup ecosystems. We define processes and best practices to unlock value for stakeholders and significantly improve return on capital.</p>

                        <div className="slider">

                            <Slider {...settings}>

                                <div id="slide0" className="slides d-flex flex-wrap align-items-center justify-content-center text-white">
                                    <img src={require("../../assets/imgs/1.jpg")} />
                                    <h4>ASSESSMENT</h4>
                                </div>

                                <div id="slide1" className="slides d-flex flex-wrap align-items-center justify-content-center text-white">
                                    <img src={require("../../assets/imgs/2.jpg")} />
                                    <h4>STRATEGY</h4>
                                </div>

                                <div id="slide2" className="slides d-flex flex-wrap align-items-center justify-content-center text-white">
                                    <img src={require("../../assets/imgs/3.jpg")} />
                                    <h4>DELIVERY</h4>
                                </div>


                            </Slider>
                        </div>


                    </div>

                </div>

            </div>
        </section>

    )

}


export default About;