import { Link } from "react-router-dom"
const Privacy = () => {
    return (
        <section className="container-fluid">

            <div className="row">

                <div className="col-12">
                    <>
                        <p>
                            <span style={{ fontSize: "26pt" }}>Privacy Policy</span>
                        </p>
                        <p>
                            <br />
                        </p>
                        <p>
                            <span style={{ fontSize: "11pt" }}>Effected on Dec 19, 2023</span>
                        </p>
                        <p>
                            <span style={{ fontSize: "11pt" }}>
                                Gapcrud Private Limited, registered and headquartered at HA 130, Salt Lake
                                City, Sector 3, Kolkata 700097, hereinafter referred to as Capsule Labs,
                                Gapcrud Consulting (
                            </span>
                            <strong>
                                <span style={{ fontSize: "11pt" }}>"Company"</span>
                            </strong>
                            <span style={{ fontSize: "11pt" }}>,&nbsp;</span>
                            <strong>
                                <span style={{ fontSize: "11pt" }}>"we"</span>
                            </strong>
                            <span style={{ fontSize: "11pt" }}>,&nbsp;</span>
                            <strong>
                                <span style={{ fontSize: "11pt" }}>"us</span>
                            </strong>
                            <span style={{ fontSize: "11pt" }}>" or&nbsp;</span>
                            <strong>
                                <span style={{ fontSize: "11pt" }}>"our''</span>
                            </strong>
                            <span style={{ fontSize: "11pt" }}>) operates&nbsp;</span>
                            <a href="https://www.capsulelabs.in">
                                <u>
                                    <span style={{ color: "#1155cc", fontSize: "11pt" }}>
                                        https://www.capsulelabs.in
                                    </span>
                                </u>
                            </a>
                            <span style={{ fontSize: "11pt" }}>,&nbsp;</span>
                            <a href="https://www.gapcrud.in/">
                                <u>
                                    <span style={{ color: "#1155cc", fontSize: "11pt" }}>
                                        https://www.gapcrud.in/
                                    </span>
                                </u>
                            </a>
                            <span style={{ fontSize: "11pt" }}>
                                &nbsp;(hereinafter referred to as the&nbsp;
                            </span>
                            <strong>
                                <span style={{ fontSize: "11pt" }}>"Services'' or "websites"</span>
                            </strong>
                            <span style={{ fontSize: "11pt" }}>
                                ). We are committed to protecting your privacy and ensuring that you have
                                a positive experience on our websites and while using our Services.&nbsp;
                            </span>
                        </p>
                        <p>
                            <span style={{ fontSize: "11pt" }}>
                                Our Privacy Policy governs your visit to the websites and its subdomains
                                and explains how we collect, protect and share Personal Data collected
                                from your use of our Service. In this Privacy Policy, the term&nbsp;
                            </span>
                            <strong>
                                <span style={{ fontSize: "11pt" }}>"personal data"</span>
                            </strong>
                            <span style={{ fontSize: "11pt" }}>
                                &nbsp;means any information that can be used to identify an individual.
                                This includes, but is not limited to, name, email ID, postal or other
                                physical addresses, title and other Personally Identifiable Information
                                (PII). This policy may be updated from time to time, for example, due to
                                operational practices or regulatory changes. Therefore, we encourage you
                                to review our Privacy Policy when you revisit our website after a period
                                of time.
                            </span>
                        </p>
                        <p>
                            <span style={{ fontSize: "11pt" }}>
                                By using our Services, you consent to the collection and use of
                                information in accordance with this Policy. Unless otherwise defined in
                                this Privacy Policy, terms used in this Privacy Policy have the same
                                meanings as in our Terms and Conditions (
                            </span>
                            <strong>
                                <span style={{ fontSize: "11pt" }}>"Terms"</span>
                            </strong>
                            <span style={{ fontSize: "11pt" }}>
                                ). Our Terms govern your use of our Service and, together with the Privacy
                                Policy, constitute your agreement with us (
                            </span>
                            <strong>
                                <span style={{ fontSize: "11pt" }}>"Agreement"</span>
                            </strong>
                            <span style={{ fontSize: "11pt" }}>).&nbsp;</span>
                        </p>
                        <h2>
                            <span style={{ fontSize: "16pt" }}>Additional Definitions</span>
                        </h2>
                        <p>
                            <br />
                        </p>
                        <p>
                            <strong>
                                <span style={{ fontSize: "11pt" }}>Data Subject</span>
                            </strong>
                            <span style={{ fontSize: "11pt" }}>&nbsp;(</span>
                            <strong>
                                <span style={{ fontSize: "11pt" }}>"subject"</span>
                            </strong>
                            <span style={{ fontSize: "11pt" }}>&nbsp;or&nbsp;</span>
                            <strong>
                                <span style={{ fontSize: "11pt" }}>"user''</span>
                            </strong>
                            <span style={{ fontSize: "11pt" }}>
                                ) means any living person who is the subject of Personal Data or his or
                                her legal representative.&nbsp;
                            </span>
                        </p>
                        <p>
                            <strong>
                                <span style={{ fontSize: "11pt" }}>Data Controller</span>
                            </strong>
                            <span style={{ fontSize: "11pt" }}>
                                &nbsp; means a natural or legal person who (either alone or jointly with
                                others) determines the purposes and manner of the processing of personal
                                data. For the purposes of this Privacy Policy, we are the data controller
                                of your data.
                            </span>
                        </p>
                        <p>
                            <strong>
                                <span style={{ fontSize: "11pt" }}>Data Processor</span>
                            </strong>
                            <span style={{ fontSize: "11pt" }}>
                                &nbsp;(or Service Provider) means any natural or legal person who
                                processes data on behalf of the Data Controller. We may process your data
                                ourselves or use the services of third party service providers to process
                                your data more effectively.
                            </span>
                        </p>
                        <p>
                            <strong>
                                <span style={{ fontSize: "11pt" }}>Usage Data</span>
                            </strong>
                            <span style={{ fontSize: "11pt" }}>
                                &nbsp;is automatically generated by the use of the Service or by the
                                infrastructure of the Service itself (e.g. URLs clicked during a page
                                visit).
                            </span>
                        </p>
                        <p>
                            <strong>
                                <span style={{ fontSize: "11pt" }}>Cookies</span>
                            </strong>
                            <span style={{ fontSize: "11pt" }}>
                                &nbsp;are small files that are temporarily stored on your computing device
                                by your web browser.
                            </span>
                        </p>
                        <p>
                            <br />
                        </p>
                        <h2>
                            <span style={{ fontSize: "16pt" }}>Collection of Personal Data</span>
                        </h2>
                        <h3>
                            <span style={{ color: "#434343", fontSize: "13.999999999999998pt" }}>
                                Personal Data or Personally Identifiable Information (PII)&nbsp;
                            </span>
                        </h3>
                        <p>
                            <span style={{ fontSize: "11pt" }}>
                                When you register to use our Service, we may ask you to provide us with
                                certain personally identifiable information that may be used to contact or
                                identify you ("Personal Data"). Personal Data includes, but is not limited
                                to, your name, email address, phone number, profile picture, address,
                                login names, screen names, handles, date of birth, gender, school
                                information, grades, graduation year, job information, designations,
                                resume, social profile information such as LinkedIn, Facebook, Google
                                Scholar, Github, etc., the content of the message and/or attachments you
                                send us, and any other information you actively provide.
                            </span>
                        </p>
                        <h3>
                            <span style={{ color: "#434343", fontSize: "13.999999999999998pt" }}>
                                Passive collection of Usage Data &nbsp;
                            </span>
                        </h3>
                        <p>
                            <span style={{ fontSize: "11pt" }}>
                                We may also collect "Usage Data" that your browser sends when you visit
                                our website or when you access the website from any device. This Usage
                                Data may include information such as your computer's Internet Protocol
                                (“IP”) address, Internet Service Provider (“ISP”), browser type, browser
                                version, the sections and assets of our website that you visit, the time
                                and date of your visit, the time spent on those pages, referring/exit
                                pages, unique device identifiers, clickstream data, geolocation, and other
                                diagnostic data. If you access the website using a device, this Usage Data
                                may include additional information such as the type of device you are
                                using, the unique identifier of your device, the IP address of your
                                device, the operating system of your device, the type of Internet browser
                                you are using, unique device identifiers, and other diagnostic data.
                            </span>
                        </p>
                        <p>
                            <span style={{ fontSize: "11pt" }}>
                                Additionally, when you use our website, network information such as
                                service-usage details may be transmitted back to us for troubleshooting or
                                improving service delivery.
                            </span>
                        </p>
                        <h3>
                            <span style={{ color: "#434343", fontSize: "13.999999999999998pt" }}>
                                Geolocation Data&nbsp;
                            </span>
                        </h3>
                        <p>
                            <span style={{ fontSize: "11pt" }}>
                                When you use our Service, we may collect and store information about your
                                location ("Location Data"). We use this data to improve and customise some
                                features of our Service, such as language settings, content delivery,
                                relevant events, etc&nbsp;
                            </span>
                        </p>
                        <p>
                            <span style={{ fontSize: "11pt" }}>
                                You can enable or disable the sharing of Location Data at any time through
                                your device's settings, but some features on our website may become
                                unavailable or may not function optimally.
                            </span>
                        </p>
                        <h3>
                            <span style={{ color: "#434343", fontSize: "13.999999999999998pt" }}>
                                Cookies/ Tracking Data
                            </span>
                        </h3>
                        <p>
                            <span style={{ fontSize: "11pt" }}>
                                Cookies are small files with data about sessions, preferences, settings or
                                security and may contain an anonymous unique identifier. Cookies are sent
                                from a website to your web browser and stored on your device. Other
                                tracking technologies such as beacons, tags, and scripts also serve a
                                similar purpose.&nbsp;
                            </span>
                        </p>
                        <p>
                            <span style={{ fontSize: "11pt" }}>
                                We may use cookies and similar tracking technologies on our website to
                                analyse usage trends, administer the website, track users' movements
                                around the website and collect information about the user base.&nbsp;
                            </span>
                        </p>
                        <p>
                            <span style={{ fontSize: "11pt" }}>
                                Your browser may provide you with a "Do Not Track" option, which allows
                                you to signal to website operators that you do not want them to track your
                                online activities across different websites. Our website honours "Do Not
                                Track" requests. &nbsp;
                            </span>
                        </p>
                        <p>
                            <span style={{ fontSize: "11pt" }}>
                                Most web browsers automatically accept cookies, but you can set your
                                browser to reject all cookies or to indicate when a cookie is being sent.
                                However, if you do not accept cookies, you may not be able to use the
                                parts of our service that depend on cookies.
                            </span>
                        </p>
                        <h3>
                            <span style={{ color: "#434343", fontSize: "13.999999999999998pt" }}>
                                Social Media Data
                            </span>
                        </h3>
                        <p>
                            <span style={{ fontSize: "11pt" }}>
                                We may offer you the option to register with us using your existing social
                                media account, such as LinkedIn, Google, Facebook, Twitter etc. In such
                                cases, we may receive certain profile information about you from your
                                social media provider. The profile information we receive may vary by
                                social media provider, but often includes your name, email address,
                                friends list and profile picture, and anything else you choose to post on
                                such a social media platform. We only use this information as stated in
                                this Privacy Policy. For more information about the use of personal
                                information by third-parties, please review the Privacy Policy of
                                respective social media companies.&nbsp;
                            </span>
                        </p>
                        <h2>
                            <span style={{ fontSize: "16pt" }}>Usage and Control of Personal Data</span>
                        </h2>
                        <p>
                            <span style={{ fontSize: "11pt" }}>
                                Your Personal Data is only used when we have a lawful basis for doing so
                                (e.g., consent, contract fulfilment, customization of services, technical
                                support, dispute resolution, fraud prevention, etc.). We process Personal
                                Data for activities related to account registration; use of certain
                                features; registering and participating in events on the website;
                                generating reports based on information collected from your use of our
                                website; requesting support for our service; participating in an online
                                survey or discussion groups or forums; customising your experience on the
                                website; supporting recruitment requests; fulfilling contractual or legal
                                obligations etc.&nbsp;
                            </span>
                        </p>
                        <p>
                            <span style={{ fontSize: "11pt" }}>
                                We will only use your Personal Data in accordance with our Privacy Policy.
                                If you do not want us to continue using your Personal Data, you can
                                request to deactivate your account in your profile settings. Digitised
                                certificates issued with your Personal Data, can be removed via an
                                explicit Certificate Deletion link in your profile section. &nbsp;
                            </span>
                        </p>
                        <p>
                            <span style={{ fontSize: "11pt" }}>
                                We may also use your Personal Data to personalise your experience in
                                providing our Services or to contact you with newsletters, marketing or
                                promotional materials, and other information that may be of interest to
                                you. You will have the opportunity to opt out of receiving any or all of
                                these communications from us by clicking on the unsubscribe link&nbsp;
                            </span>
                        </p>
                        <p>
                            <span style={{ fontSize: "11pt" }}>
                                Passively collected data of website visitors do not have an expiration
                                date, and we do not delete this information. It is stored by us in the
                                form of logs or in tools such as Google Analytics, LinkedIn Insights,
                                Facebook Audience Insights and others. This data is used in the form of
                                aggregates and it may not be personally identifiable.
                            </span>
                        </p>
                        <h3>
                            <span style={{ color: "#434343", fontSize: "13.999999999999998pt" }}>
                                Data Retention
                            </span>
                        </h3>
                        <p>
                            <span style={{ fontSize: "11pt" }}>
                                We retain all Personal Data in accordance with our data retention policy,
                                which complies with applicable Data Protection Laws. The retention period
                                depends on the type of data and the existence of the user account. Some
                                Personal Data can be deleted immediately by changing the profile. We
                                retain Personal Data for as long as the account is active and delete it
                                within 30 days of account deletion. We do not support temporary
                                deactivation of an account. Residual data may be retained in certain logs
                                and may take up to one year to be purged. &nbsp;
                            </span>
                        </p>
                        <p>
                            <span style={{ fontSize: "11pt" }}>
                                We will retain and use your Personal Data to the extent necessary to
                                comply with our legal obligations (for example, if we need to retain your
                                data to comply with applicable laws), resolve disputes, and enforce our
                                legal agreements and policies.
                            </span>
                        </p>
                        <p>
                            <span style={{ fontSize: "11pt" }}>
                                We will also retain Usage Data for internal analysis purposes. Usage Data
                                is generally retained for a shorter period of time, unless such data is
                                used to improve the security or functionality of our Service or we are
                                required by law to retain such data for longer periods of time.
                            </span>
                        </p>
                        <h3>
                            <span style={{ color: "#434343", fontSize: "13.999999999999998pt" }}>
                                Data Transfer
                            </span>
                        </h3>
                        <p>
                            <span style={{ fontSize: "11pt" }}>
                                Your information, including personally identifiable information, may be
                                transferred to and stored on computers outside of your state, province,
                                country or other governmental jurisdiction where privacy laws may differ
                                from those of your jurisdiction. If you are located outside of India and
                                provide information to us, please note that we will transfer the data,
                                including Personal Data, to India and process it there. Your consent to
                                this Privacy Policy, followed by the submission of such information,
                                constitutes your consent to such transfer.
                            </span>
                        </p>
                        <p>
                            <span style={{ fontSize: "11pt" }}>
                                The Company will take all reasonable steps to ensure that your information
                                is treated securely and in accordance with this Privacy Policy, and no
                                transfer of your Personal Data will take place to any organisation or
                                country unless appropriate controls are in place to include the security
                                of your data and other personal information.
                            </span>
                        </p>
                        <h3>
                            <span style={{ color: "#434343", fontSize: "13.999999999999998pt" }}>
                                Data Disclosure
                            </span>
                        </h3>
                        <p>
                            <span style={{ fontSize: "11pt" }}>
                                We may release personal information when we believe in good faith that
                                release is necessary to comply with the law, enforce or apply our Terms of
                                Use and other agreements, or protect our rights, property, or safety, our
                                employees, our users, or others. This includes sharing information with
                                other companies and organisations to protect against fraud and reduce
                                credit risk.
                            </span>
                        </p>
                        <p>
                            <br />
                        </p>
                        <p>
                            <span style={{ fontSize: "11pt" }}>
                                We share Personal Data within the Company, its affiliates, and with
                                third-party service providers for data processing or storage. This is done
                                to complete/execute certain transactions, including, but not limited to,
                                payments, hosting websites, hosting events and seminar registrations, and
                                providing customer support.
                            </span>
                        </p>
                        <p>
                            <br />
                        </p>
                        <p>
                            <span style={{ fontSize: "11pt" }}>
                                We may engage third party companies and individuals to facilitate our
                                Service ("Service Providers"), provide the Service on our behalf, perform
                                Service-related services (including Continuous Integration / Continuous
                                Development related automations), or assist us in analysing the use of our
                                Service. These third parties will only have access to your Personal Data
                                to perform these tasks on our behalf and are required not to share or use
                                it for any other purpose.
                            </span>
                        </p>
                        <p>
                            <br />
                        </p>
                        <p>
                            <span style={{ fontSize: "11pt" }}>
                                If we file for bankruptcy or are involved in a merger or acquisition, our
                                user data may be transferred to or acquired by a third party. You
                                acknowledge that such transfers may occur and that any acquirer of us or
                                our assets may continue to use your Personal Data as set forth in this
                                Policy.&nbsp;
                            </span>
                        </p>
                        <p>
                            <br />
                        </p>
                        <p>
                            <span style={{ fontSize: "11pt" }}>
                                Except as provided above, you will be notified when your personal
                                information may be disclosed to third parties and will have the
                                opportunity to prevent such disclosure.
                            </span>
                        </p>
                        <h3>
                            <span style={{ color: "#434343", fontSize: "13.999999999999998pt" }}>
                                Data Security
                            </span>
                        </h3>
                        <p>
                            <span style={{ fontSize: "11pt" }}>
                                We are committed to protecting the Personal Data you share with us. We use
                                a combination of industry-standard security technologies, procedures and
                                organisational measures to help protect your personal information from
                                unauthorised access, use or disclosure. Although we strive to protect your
                                personal information using commercially reasonable means, we cannot
                                guarantee absolute security due to the vulnerability of Internet data
                                transmission and cloud storage.
                            </span>
                        </p>
                        <h2>
                            <span style={{ fontSize: "16pt" }}>Additional Usage of Personal Data</span>
                        </h2>
                        <h3>
                            <span style={{ color: "#434343", fontSize: "13.999999999999998pt" }}>
                                Analytics
                            </span>
                        </h3>
                        <p>
                            <span style={{ fontSize: "11pt" }}>
                                We use Google Analytics on our website to a) monitor website traffic and
                                user behavioural flows b) measure the effectiveness of products and
                                services on the website and c) measure the effectiveness of off-site
                                marketing campaigns and tactics. Google has developed a browser add-on to
                                disable Google Analytics. If you want to disable Google Analytics, you can
                                download and install the add-on for your web browser&nbsp;
                            </span>
                            <a href="https://tools.google.com/dlpage/gaoptout">
                                <u>
                                    <span style={{ color: "#1155cc", fontSize: "11pt" }}>here</span>
                                </u>
                            </a>
                            <span style={{ fontSize: "11pt" }}>.</span>
                        </p>
                        <h3>
                            <span style={{ color: "#434343", fontSize: "13.999999999999998pt" }}>
                                External Content and Links
                            </span>
                        </h3>
                        <p>
                            <span style={{ fontSize: "11pt" }}>
                                As you navigate our website, you may be directed to content or
                                functionality that is hosted by a third party. You may also encounter
                                links to other sites that are not operated by us. We have no control over,
                                and assume no responsibility for, the content, privacy policies, or
                                practices of any third party websites or services. In such cases, please
                                refer to the privacy and security policies of the third party website/
                                service before using it.
                            </span>
                        </p>
                        <h3>
                            <span style={{ color: "#434343", fontSize: "13.999999999999998pt" }}>
                                Payments
                            </span>
                        </h3>
                        <p>
                            <span style={{ fontSize: "11pt" }}>
                                We may offer paid events and/or services within the Service. In this case,
                                we use third party services, such as Razorpay or Paytm, for payment
                                processing (e.g., payment processors). We will not store or collect your
                                payment card information. This information is shared directly with our
                                third-party payment processors, whose use of your personal information is
                                governed by their respective Privacy Policies.
                            </span>
                        </p>
                        <h3>
                            <span style={{ color: "#434343", fontSize: "13.999999999999998pt" }}>
                                Non-Transferability
                            </span>
                        </h3>
                        <p>
                            <span style={{ fontSize: "11pt" }}>
                                Rights to a user account and paid courses may not be transferred or
                                assigned to another account/person/entity, whether by division,
                                solicitation, operation of law, or otherwise. Any efforts to share account
                                rights, purchased courses or other privileges granted to the user are void
                                and will not be considered. Consequently, the transfer of account rights
                                is not permitted.
                            </span>
                        </p>
                        <h3>
                            <span style={{ color: "#434343", fontSize: "13.999999999999998pt" }}>
                                Advertising
                            </span>
                        </h3>
                        <p>
                            <span style={{ fontSize: "11pt" }}>
                                Although the current version of our Service does not include
                                advertisements for external products or services, we reserve the right to
                                include advertisers. In such cases, advertisers may use cookies and web
                                beacons on our website and we may display personalised advertisements to
                                our users. Each of our advertising partners has its own Privacy Policy for
                                handling user data.
                            </span>
                        </p>
                        <h3>
                            <span style={{ color: "#434343", fontSize: "13.999999999999998pt" }}>
                                Children’s Privacy
                            </span>
                        </h3>
                        <p>
                            <span style={{ fontSize: "11pt" }}>
                                Our Services are not intended for use by children under the age of 18
                                ("Child" or "Children").
                            </span>
                        </p>
                        <p>
                            <span style={{ fontSize: "11pt" }}>
                                We do not knowingly collect personally identifiable information from
                                children under 18. If you learn that a Child has provided us with Personal
                                Data, please contact us at info@capsulelabs.in. If we discover that we
                                have collected personally identifiable information from children without
                                verification of parental consent, we will take steps to remove that
                                information from our servers.
                            </span>
                        </p>
                        <p>
                            <br />
                        </p>
                        <h2>
                            <span style={{ fontSize: "16pt" }}>Data Subject Rights&nbsp;</span>
                        </h2>
                        <p>
                            <span style={{ fontSize: "11pt" }}>
                                Our Services are intended to be consumed by residents of the Indian
                                subcontinent. However, if you are accessing our website from a different
                                jurisdiction, you may have specific rights available to you.
                            </span>
                        </p>
                        <h3>
                            <span style={{ color: "#434343", fontSize: "13.999999999999998pt" }}>
                                General Data Protection Regulation (GDPR)
                            </span>
                        </h3>
                        <p>
                            <span style={{ fontSize: "11pt" }}>
                                If you are a resident of the European Union (EU) or the European Economic
                                Area (EEA), in certain cases you have the following data protection rights
                                covered by the GDPR:
                            </span>
                        </p>
                        <ol>
                            <li style={{ listStyleType: "decimal", fontSize: "11pt" }}>
                                <p>
                                    <span style={{ fontSize: "11pt" }}>
                                        The right to access, update or delete the data we hold about you;
                                    </span>
                                </p>
                            </li>
                            <li style={{ listStyleType: "decimal", fontSize: "11pt" }}>
                                <p>
                                    <span style={{ fontSize: "11pt" }}>
                                        The right to object to the processing of your personal data by us;
                                    </span>
                                </p>
                            </li>
                            <li style={{ listStyleType: "decimal", fontSize: "11pt" }}>
                                <p>
                                    <span style={{ fontSize: "11pt" }}>
                                        The right to request that we restrict the processing of your personal
                                        data;
                                    </span>
                                </p>
                            </li>
                            <li style={{ listStyleType: "decimal", fontSize: "11pt" }}>
                                <p>
                                    <span style={{ fontSize: "11pt" }}>
                                        The right to receive a copy of your personal data in a structured,
                                        machine-readable and commonly used format;
                                    </span>
                                </p>
                            </li>
                            <li style={{ listStyleType: "decimal", fontSize: "11pt" }}>
                                <p>
                                    <span style={{ fontSize: "11pt" }}>
                                        The right to withdraw your consent at any time where we rely on your
                                        consent to process your personal data;
                                    </span>
                                </p>
                            </li>
                        </ol>
                        <p>
                            <span style={{ fontSize: "11pt" }}>
                                We aim to take reasonable steps to allow you to correct, amend, delete or
                                restrict the use of your personal data. If you would like to know what
                                personal data we hold about you and if you would like it removed from our
                                systems, please contact us. Please note that we may ask you to verify your
                                identity before responding to such requests. Please note that without some
                                of the required data, we may not be able to provide the Service.
                            </span>
                        </p>
                        <p>
                            <span style={{ fontSize: "11pt" }}>
                                You have the right to complain to a data protection authority about our
                                collection and use of your personal information. For more information,
                                please contact your local data protection authority in the European
                                Economic Area (EEA).
                            </span>
                        </p>
                        <h3>
                            <span style={{ color: "#434343", fontSize: "13.999999999999998pt" }}>
                                California Privacy Protection Act (CalOPPA)
                            </span>
                        </h3>
                        <p>
                            <span style={{ fontSize: "11pt" }}>
                                CalOPPA is a California state law requiring commercial websites and online
                                services to post a privacy policy. Its scope requires individuals or
                                companies in the United States (and potentially around the world) that
                                operate websites that collect personal information from California
                                consumers to post a prominent privacy policy on their website detailing
                                what information is collected and with whom it is shared, and to comply
                                with that privacy policy.
                            </span>
                        </p>
                        <p>
                            <span style={{ fontSize: "11pt" }}>
                                Pursuant to CalOPPA, we agree to the following:
                            </span>
                        </p>
                        <ol>
                            <li style={{ listStyleType: "decimal", fontSize: "11pt" }}>
                                <p>
                                    <span style={{ fontSize: "11pt" }}>
                                        Users can visit our website anonymously;
                                    </span>
                                </p>
                            </li>
                            <li style={{ listStyleType: "decimal", fontSize: "11pt" }}>
                                <p>
                                    <span style={{ fontSize: "11pt" }}>
                                        Our link to the privacy policy includes the word "Privacy" and is
                                        easily found on the homepage of our website;
                                    </span>
                                </p>
                            </li>
                            <li style={{ listStyleType: "decimal", fontSize: "11pt" }}>
                                <p>
                                    <span style={{ fontSize: "11pt" }}>
                                        Users will be notified of changes to the privacy policy on our
                                        "Privacy Policy" page;
                                    </span>
                                </p>
                            </li>
                            <li style={{ listStyleType: "decimal", fontSize: "11pt" }}>
                                <p>
                                    <span style={{ fontSize: "11pt" }}>
                                        Users can change their personal information by sending us an email at
                                        info@capsulelabs.in
                                    </span>
                                </p>
                            </li>
                        </ol>
                        <h3>
                            <span style={{ color: "#434343", fontSize: "13.999999999999998pt" }}>
                                California Consumer Privacy Act (CCPA)
                            </span>
                        </h3>
                        <p>
                            <span style={{ fontSize: "11pt" }}>
                                If you are a California resident, you have the right to know what data we
                                collect about you, to request that we delete your data and not to sell
                                (share) it. To exercise your data protection rights, you can make certain
                                requests and ask us:
                            </span>
                        </p>
                        <ol>
                            <li style={{ listStyleType: "decimal", fontSize: "11pt" }}>
                                <p>
                                    <span style={{ fontSize: "11pt" }}>
                                        What personal information we have about you.
                                    </span>
                                </p>
                            </li>
                        </ol>
                        <p>
                            <span style={{ fontSize: "11pt" }}>
                                If you make this request, we will respond to you:
                            </span>
                        </p>
                        <p>
                            <span style={{ fontSize: "11pt" }}>
                                a. The categories of personal data we have collected about you.
                            </span>
                        </p>
                        <p>
                            <span style={{ fontSize: "11pt" }}>
                                b. The categories of sources from which we have collected your personal
                                data.
                            </span>
                        </p>
                        <p>
                            <span style={{ fontSize: "11pt" }}>
                                c. The business or commercial purpose for collecting or selling your
                                personal information.
                            </span>
                        </p>
                        <p>
                            <span style={{ fontSize: "11pt" }}>
                                d. The categories of third parties with whom we share personal
                                information.
                            </span>
                        </p>
                        <p>
                            <span style={{ fontSize: "11pt" }}>
                                e. The specific personal information we have collected about you.
                            </span>
                        </p>
                        <p>
                            <span style={{ fontSize: "11pt" }}>
                                f. A list of the categories of personal information we have sold, along
                                with the category of any other entity to which we have sold it. If we have
                                not sold your personal information, we will let you know.
                            </span>
                        </p>
                        <p>
                            <span style={{ fontSize: "11pt" }}>
                                g. A list of the categories of personal information we have shared for a
                                business purpose, along with the category of each other entity with which
                                we have shared it.
                            </span>
                        </p>
                        <p>
                            <span style={{ fontSize: "11pt" }}>
                                Please note that you are entitled to request this information from us up
                                to twice in a rolling twelve-month period. If you make this request, the
                                information provided may be limited to the personal information we have
                                collected about you during the previous 12 months.
                            </span>
                        </p>
                        <ol start={2}>
                            <li style={{ listStyleType: "decimal", fontSize: "11pt" }}>
                                <p>
                                    <span style={{ fontSize: "11pt" }}>
                                        Deletion of Your Personal Information.&nbsp;
                                    </span>
                                </p>
                            </li>
                        </ol>
                        <p>
                            <span style={{ fontSize: "11pt" }}>
                                If you make this request, we will delete the personal information we hold
                                about you at the time of your request from our records and instruct all
                                service providers to do the same. In some cases, erasure may be
                                accomplished by de-identifying the data. If you choose to delete your
                                personal information, you may no longer be able to use certain features
                                that require your personal information. You can send us an email&nbsp;
                            </span>
                            <Link to={"/#brands"}>
                                <u>
                                    <span style={{ color: "#1155cc", fontSize: "11pt" }}>here</span>
                                </u>
                            </Link>
                            <span style={{ fontSize: "11pt" }}>
                                &nbsp;to delete your personal information&nbsp;
                            </span>
                        </p>
                        <ol start={3}>
                            <li style={{ listStyleType: "decimal", fontSize: "11pt" }}>
                                <p>
                                    <span style={{ fontSize: "11pt" }}>
                                        To stop the sale of your personal information.&nbsp;
                                    </span>
                                </p>
                            </li>
                        </ol>
                        <p>
                            <span style={{ fontSize: "11pt" }}>
                                We do not sell or rent your personal information to third parties for any
                                purpose. We do not sell your personal information for financial
                                consideration. However, under certain circumstances, sharing personal
                                information with third parties or within our corporate family for no
                                financial consideration may be considered a "sale" under California law.
                                You are the sole owner of your personal information and may request its
                                disclosure or deletion at any time. Please send an email&nbsp;
                            </span>
                            <Link to={"/#brands"}>
                                <u>
                                    <span style={{ color: "#1155cc", fontSize: "11pt" }}>here</span>
                                </u>
                            </Link>
                            <span style={{ fontSize: "11pt" }}>
                                &nbsp;if you want us to stop the sale of your Personal Data..
                            </span>
                        </p>
                        <ol start={4}>
                            <li style={{ listStyleType: "decimal", fontSize: "11pt" }}>
                                <p>
                                    <span style={{ fontSize: "11pt" }}>
                                        Unsubscribing from marketing and promotional content.&nbsp;
                                    </span>
                                </p>
                            </li>
                        </ol>
                        <p>
                            <span style={{ fontSize: "11pt" }}>
                                You may unsubscribe from our marketing and promotional communications at
                                any time by clicking on the unsubscribe link in the emails we send you or
                                by contacting us using the information provided in the section below. You
                                will then be removed from our marketing lists. However, we may continue to
                                communicate with you, for example, to send you service-related messages
                                necessary to manage and use your account, to respond to service requests,
                                or for purposes other than marketing&nbsp;
                            </span>
                        </p>
                        <p>
                            <span style={{ fontSize: "11pt" }}>
                                Please note: If you ask us to delete or stop selling your information, it
                                may affect your experience with us and you may not be able to participate
                                in certain programs or membership services that require the use of your
                                personal information to function. However, we will not discriminate
                                against you under any circumstances if you exercise your rights.
                            </span>
                        </p>
                        <p>
                            <span style={{ fontSize: "11pt" }}>
                                To exercise your California privacy rights described above, please contact
                                us&nbsp;
                            </span>
                            <Link to={"/#brands"}>
                                <u>
                                    <span style={{ color: "#1155cc", fontSize: "11pt" }}>here</span>
                                </u>
                            </Link>
                            <span style={{ fontSize: "11pt" }}>
                                &nbsp;to submit your requests.Your privacy rights described above fall
                                under the CCPA, short for California Consumer Privacy Act. For more
                                information, please visit the official California Legislative Information
                                website. The CCPA went into effect on 01/01/2020.
                            </span>
                        </p>
                        <p>
                            <br />
                        </p>
                        <h2>
                            <span style={{ fontSize: "16pt" }}>Update</span>
                        </h2>
                        <p>
                            <span style={{ fontSize: "11pt" }}>
                                This Privacy Policy was last updated on: 19 Dec, 2023
                            </span>
                        </p>
                        <p>
                            <span style={{ fontSize: "11pt" }}>
                                If we update, add to, or change our privacy policy, we will post those
                                changes on our website, under the “Privacy” section. If you need more
                                information or have any questions about our privacy policy, please feel
                                free to contact us by emailing us at info@capsulelabs.in.
                            </span>
                        </p>
                        <p>
                            <br />
                        </p>
                        <h2>
                            <span style={{ fontSize: "16pt" }}>&nbsp;</span>
                        </h2>
                        <p>
                            <br />
                        </p>
                        <p>
                            <br />
                        </p>
                    </>
                </div>

            </div>

        </section>


    )
}

export default Privacy