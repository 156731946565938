import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {

    return (

        <footer className="py-5">

            <div className="container">
                <div className="row align-items-center">

                    <div className="col-md-4 text-center text-md-left">
                        {/* <Link to="/"><img src={require("../assets/imgs/gapcrudw.png")} className="img-fluid" style={{maxWidth:200, borderRadius:5}} /></Link> */}
                        <a target="_blank" href="https://www.linkedin.com/company/gapcrud-consulting"><i className="fa fa-linkedin" aria-hidden="true"></i></a>

                    </div>

                    <div className="col-md-4 text-center text-center order-md-2 order-3">
                        <p className="mb-0"><small>© Gapcrud Pvt Ltd, {new Date().getFullYear()}</small></p>
                    </div>

                    <div className="col-md-4 text-center text-md-right order-md-3 order-2">
                        <Link className="d-inline-block py-3 py-md-0" to="/privacy">Privacy Policy</Link>
                    </div>

                </div>
                {/* 
                <div className="row">

                    <div className="col-12 text-center">
                        <p className="mb-0"><small>© Gapcrud Pvt Ltd, {new Date().getFullYear()}</small></p>
                    </div>

                </div> */}
            </div>

        </footer>

    )

}

export default Footer;