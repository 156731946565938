import React, { useState, useEffect } from "react";


const Fulltext = (props) => {

    useEffect(() => {

        // console.clear();
        // console.log(props);

    }, [props])

    return (

        <div id={props.id} className={props.side && props.side === "right" ? "fullht d-flex flex-wrap align-items-center fullLeft" : "fullht d-flex flex-wrap align-items-center fullRight"}>

            <div className="container-fluid">

                <div className={props.side && props.side === "right" ? "row justify-content-end" : "row"}>
                    <div className="col-md-1"></div>

                    <div className="col-md-4 py-5">
                        {props.text ? <h2>{props.text}</h2> : null}

                        {props.text_content ? (
                            <>
                                <props.text_content />
                            </>
                        ) : null}
                    </div>
                    <div className="col-md-1"></div>

                </div>

            </div>

        </div>

    )

}

export default Fulltext;