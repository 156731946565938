import React, { useState, useEffect } from "react";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";



const Brands = (props) => {

    const onChange = (value) => {
        console.log("Captcha value:", value);
    }


    const [mail, setMail] = useState({
        name: "",
        ph: "",
        mail: "",
        msg: ""
    })

    const [form, setForm] = useState(false)
    const [flag, setFlag] = useState(false)


    const setMailVal = (attr, val) => {

        let ml = { ...mail }
        ml[attr] = val
        setMail(ml)

    }

    const postMail = (e) => {

        e.preventDefault();

        console.log(mail)

        axios.post(process.env.REACT_APP_BACKEND_SERVER + "/mail/gapcrud", mail)
            .then(el => {

                console.log(el.data)

                let obj = el.data

                if (obj && obj === 'mail sent!') {
                    alert("Mail sent!")
                    setMail({
                        name: "",
                        ph: "",
                        mail: "",
                        msg: ""
                    })
                    setForm(false)
                }

            })

    }


    useEffect(() => {

        console.log(props.loc)

        if (props.loc && props.loc.hash && props.loc.hash === '#brands') {
            setForm(true)
        }

    }, [props])

    return (

        <section className="d-flex flex-wrap align-items-center brands faded text-center text-md-left" id="brands">

            <div className="container">

                <div className="row">


                    <div className="col-md-5 text-center py-0 py-md-5">
                        <h3>PORTFOLIO BRANDS</h3>

                        <br />

                        <a target="_blank" href="https://www.capsulelabs.in/analytics"><img src={require("../../assets/imgs/live.png")} alt="Capsulelabs Live Reports" /></a>
                        <a target="_blank" href="https://shots.capsulelabs.in"><img src={require("../../assets/imgs/Shots.png")} alt="Shots" /></a>
                        <a target="_blank" href="https://www.capsulelabs.in"><img src={require("../../assets/imgs/cap.png")} alt="Capsulelabs" /></a>
                        <a target="_blank" href="https://greatinternships.in"><img src={require("../../assets/imgs/GI.png")} alt="Great Internships" /></a>
                        <a target="_blank" href="https://gapcrud.in"><img src={require("../../assets/imgs/gapcrud.jpg")} className="img-fluid mt-3" style={{ maxWidth: 200, borderRadius: 5 }} /></a>

                    </div>

                    <div className="col-md-2 text-center">
                        <span className="d-none d-md-block m-auto h-100 separator"></span>
                    </div>

                    <div className="col-md-5 mt-0 mt-md-0 py-0 py-md-5">

                        <h3 className="mb-0">Contact:</h3>
                        <br />
                        <p className="d-flex flex-wrap justify-content-md-between justify-content-center h5 font-weight-normal small">
                            <span className="d-inline-block mr-2"><i className="fa fa-envelope-o" aria-hidden="true"></i> info@capsulelabs.in</span>

                            <span><i className="fa fa-phone" aria-hidden="true"></i> +91 86704 53602</span>
                        </p>

                        <br /><br />
                        <h3 className="d-flex flex-wrap justify-content-between align-items-center">For Bookings: <button onClick={() => setForm(!form)} className="btn p-2" style={{ borderColor: "#555" }}>{form ? <i className="fa fa-times" /> : "Contact Us"}</button></h3>


                        {form ? (
                            <form className="" onSubmit={e => postMail(e)}>
                                <div className="container-fluid px-0">

                                    <div className="row">

                                        <div className="form-group col-6">
                                            <input value={mail.name} type="text" className="form-control" placeholder="Your name *" required onChange={e => setMailVal("name", e.target.value)} />
                                        </div>
                                        <div className="form-group col-6">
                                            <input value={mail.mail} type="email" className="form-control" placeholder="Your Email *" required onChange={e => setMailVal("mail", e.target.value)} />
                                        </div>
                                        <div className="form-group col-12">
                                            <input value={mail.ph} type="number" className="form-control" placeholder="Your Phone No" onChange={e => setMailVal("ph", e.target.value)} />
                                        </div>
                                        <div className="form-group col-12">
                                            <textarea value={mail.msg} className="form-control" placeholder="Your Message *" required onChange={e => setMailVal("msg", e.target.value)} />
                                        </div>

                                    </div>

                                </div>

                                <ReCAPTCHA
                                    sitekey="6Le28SIpAAAAAF0UONzmJiHEJ4JUiULrfdxuXqVY"
                                    onChange={e => {
                                        console.log(e)
                                        setFlag(true)

                                    }}
                                />

                                {flag ? (
                                    <button type="submit" className="btn border w-100">Submit</button>
                                ) : null}
                            </form>
                        ) : null}





                    </div>

                </div>

            </div>

        </section>

    )

}

export default Brands;