import React from "react";


const Engagement = () => {
    return (

        <div>

            <p>We conduct the following essential audits to take stock of your institutions’ hidden strengths</p>

            <ul>
                <li>Student Quality Assessment</li>
                <li>Alumni Network Assessment</li>
                <li>Intellectual Capital Assessment</li>
                <li>Incubation Readiness Assessment</li>
            </ul>

        </div>

    )
}

export default Engagement;