import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router";
import { useLocation, useNavigate } from 'react-router-dom';
import Privacy from "./common/Privacy";


import Header from "./common/Header";
import Home from "./Home/Index";

import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "./App.css";

import Footer from "./common/Footer"

function App() {

  let loc = useLocation()

  const [coockieModal, setcoockieModal] = useState({
    show: true,
    agreed: false,
    closed: false,
    coockieChecked: false
  })
  const getCookie = () => {

    let ck = document.cookie.split(" ")

    let cookie_target = ck.filter(el => el.includes("coockieModal"))

    if (cookie_target.length) {

      cookie_target[0] = cookie_target[0].replace('coockieModal=', "")
      cookie_target[0] = cookie_target[0].replace(';', "")
      console.log(cookie_target[0])



      function isJSONParsable(str) {
        try {
          JSON.parse(str);
          return true;
        } catch (e) {
          return false;
        }
      }

      // Example usage:
      var jsonString = '{"key": "value"}';
      if (isJSONParsable(cookie_target[0])) {
        let obj = JSON.parse(cookie_target[0])

        obj.coockieChecked = true

        if (!obj.agreed) {
          obj.closed = false
          obj.show = true
        }

        console.log(obj)
        setcoockieModal(obj)

        document.cookie = `coockieModal=${JSON.stringify(obj)}; path=/`;
      } else {

        console.log("*************============ERROR in COOCKIE obj=============***************")


      }





    }
    else {
      let ck = { ...coockieModal }
      ck.coockieChecked = true
      setcoockieModal(ck)
      document.cookie = `coockieModal=${JSON.stringify(ck)}; path=/`;
    }

  }


  const closePolicyModal = () => {

    let ck = { ...coockieModal }
    ck.closed = true
    ck.show = false
    ck.agreed = true
    document.cookie = `coockieModal=${JSON.stringify(ck)}; path=/`;
    setcoockieModal(ck)
  }

  useEffect(() => {

    getCookie()

    console.log(loc.hash.replace("#", ""))

    if (loc.hash && loc.hash !== "") {

      let elem = document.getElementById(loc.hash.replace("#", ""))

      if (elem) {
        console.log(elem.offsetTop)

        window.scroll({
          top: elem.offsetTop,
          behavior: 'smooth'
        })

      }

    }



  }, [loc])



  return (
    <div className="App">



      <Header />
      <Routes>
        <Route path="/" element={<Home loc={loc} />} />
        <Route path="/privacy" element={<Privacy />} />
      </Routes>
      <Footer />

      <div className={coockieModal.show && coockieModal.coockieChecked ? "cookieHolder shadow border" : "cookieHolder shadow border hidden"}>
        <button className="btn text-white" onClick={() => closePolicyModal()}><i className="fa fa-times" /></button>
        <p className='mb-0'>We use cookies on our website to give you the most relevant experience. By continuing to use the site, you agree to the use of cookies.</p>
      </div>



    </div>
  );
}

export default App;
